import { useEffect, useState } from 'react';

export const useCamera = (videoConstraints) => {
  const [stream, setStream] = useState(null);
  const [isTorch, setIsTorch] = useState(false); // 플래시가 있는지 없는지 확인
  const [track, setTrack] = useState(null);
  const [facingMode, setFacingMode] = useState(videoConstraints.facingMode);
  const [isFlash, setIsFlash] = useState(false);
  const [videoResolution, setVideoResolution] = useState({
    width: 0,
    height: 0,
  });

  useEffect(() => {
    const setupSteam = async () => {
      try {
        const devices = await navigator.mediaDevices.enumerateDevices();
        const cameras = devices.filter(
          (device) => device.kind === 'videoinput'
        );

        if (cameras.length === 0) {
          throw new Error('이 기기에서 카메라를 찾을 수 없습니다.');
        }

        // 카메라가 하나인 경우 facingMode를 비활성화
        const cameraSettings =
          cameras.length > 1
            ? {
                deviceId: cameras[cameras.length - 1].deviceId,
                facingMode: { exact: facingMode },
              }
            : {
                deviceId: cameras[0].deviceId,
                facingMode: { exact: facingMode },
              }; // 하나일 경우 facingMode 속성 제거

        // 스트림 생성 및 비디오 트랙 가져오기
        const stream = await navigator.mediaDevices.getUserMedia({
          video: {
            ...cameraSettings,
            width: { ideal: videoConstraints.width },
            height: { ideal: videoConstraints.height },
            facingMode: { exact: facingMode },
            advanced: [
              {
                zoom: 1,
              },
            ],
          },
        });

        setStream(stream);
      } catch (error) {
        console.error(error);
      }
    };

    setupSteam();
  }, [videoConstraints, facingMode]);

  useEffect(() => {
    if (stream) {
      const track = stream.getVideoTracks()[0];
      const capabilities = track.getCapabilities();
      const isTorch = capabilities.torch;

      setTrack(track);
      setIsTorch(isTorch);
      setVideoResolution({
        width: track.getSettings().width,
        height: track.getSettings().height,
      });
    }
  }, [stream]);

  const toggleFacingMode = () => {
    setFacingMode((prev) => (prev === 'environment' ? 'user' : 'environment'));
  };

  const toggleTorch = () => {
    setIsFlash((prev) => !prev);
  };

  useEffect(() => {
    if (!track || !isTorch) return;
    track
      .applyConstraints({
        advanced: [{ torch: isFlash }],
      })
      .catch((err) => {
        console.error('Torch 적용 중 오류:', err);
      });
  }, [track, isFlash, isTorch]);

  return {
    stream,
    isTorch,
    track,
    facingMode,
    toggleFacingMode,
    videoResolution,
    isFlash,
    toggleTorch,
  };
};
