/** 해상도 구하기 함수 */
export const getImageResolution = (base64) => {
  return new Promise((resolve, reject) => {
    // Image 객체 생성
    const img = new Image();

    // 로드 완료 시 해상도 확인
    img.onload = () => {
      const width = img.width;
      const height = img.height;
      resolve({ width, height });
    };

    // 에러 발생 시 reject
    img.onerror = (error) => reject(error);

    // src에 Base64 데이터를 할당
    img.src = base64;
  });
};
